import React, { useEffect, useMemo, useState } from 'react';
import { isArray, isEmpty } from 'lodash';
import { Bus, DedicatedEventProvider, isHidden } from '@apollo/core';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import cx from 'classnames';
import TopEvent from '../../../../features/Sports/TopEvents/TopEvent/TopEvent';
import BonusBanner from '../../Bonus/BonusBanner';
import { EventLink } from '../../Sport/SportLinks';
import TopEventTime from '../../../../features/Sports/TopEvents/TopEvent/TopEventTime';
import useTranslate from '../../I18n/Interpreter';

const SportFeaturedEvent = ({ data }) => {
  const [sportState] = useSportState();
  const [events, setEvents] = useState({});

  const t = useTranslate();

  // Prevent falling into an endless loop if the API doesn't return a result
  const [attemptedToLoad, setAttemptedToLoad] = useState(false);

  const isLoading = SportSelector.selectIsLoading();

  const eventIds = useMemo(() => {
    if (!isArray(data)) {
      return [];
    }
    return data.map(({ event }) => event?.id).filter((id) => !!id);
  }, [data]);

  useEffect(() => {
    if (isLoading || isEmpty(eventIds)) {
      return;
    }
    let discoveredEvents = {};
    eventIds.forEach((id) => {
      const cachedEvent = sportState.eventsById?.[id];
      if (cachedEvent) {
        discoveredEvents = {
          ...discoveredEvents,
          [cachedEvent.id]: cachedEvent,
        };
      } else if (!attemptedToLoad) {
        Bus.emit(Bus.events.sport.sportLoadSingleEvent, {
          eventId: id,
          marketGroup: 'MAIN',
        });
        setAttemptedToLoad(true);
      }
      setEvents(discoveredEvents);
    });
  }, [attemptedToLoad, eventIds, isLoading, sportState.eventsById]);

  if (isEmpty(eventIds)) {
    return null;
  }

  return data.map((d, idx) => {
    const { widgetTitle, title, event } = d;
    const fullEvent = events[event?.id];

    const isEventHidden = isEmpty(fullEvent)
      || isHidden(fullEvent)
      || !hasMarketTypes(fullEvent)
      || fullEvent.startTime < Date.now();

    if (isEventHidden) {
      return null;
    }

    const classes = cx([
      isEmpty(fullEvent?.participants) ? 'cms-widget--empty-participants' : '',
      fullEvent?.outright ? ' cms-widget--many-markets' : '',
    ]);

    return (
      <div
        key={`${fullEvent.id}-${idx}`}
        className={`cms-widget cms-widget--sport-featured-event ${classes}`}
      >
        <div className='cms-widget__col'>
          <h4 className='cms-widget__subtitle'>
            <span className={`icon cms-widget__subtitle-icon sportIcon-${fullEvent.sportId}`} />
            {widgetTitle}
          </h4>
          <h3 className='cms-widget__title'>
            <EventLink className='cms-widget__title-link' event={fullEvent}>
              {title}
            </EventLink>
          </h3>
          <div className='cms-widget__date-start'>
            <TopEventTime event={fullEvent} />
          </div>
        </div>
        <div className='cms-widget__col'>
          <BonusBanner event={fullEvent} />
          <div className='cms-widget__event'>
            <DedicatedEventProvider event={fullEvent} key={fullEvent.id}>
              <TopEvent className='sport-events__list-item' />
            </DedicatedEventProvider>
          </div>
          <EventLink className='cms-widget__link-all' event={fullEvent}>
            {t('see_all')}
          </EventLink>
        </div>
      </div>
    );
  });
};
export default SportFeaturedEvent;
