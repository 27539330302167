export const PARTICLES_CONFIG = {
  fullScreen: {
    zIndex: 999,
  },
  background: {
    color: {
      value: '#000000',
    },
    opacity: 0.6,
  },
  particles: {
    shape: {
      type: 'image',
      options: {
        image: [
          {
            src: '/static/assets/images/icons/bp-odds-boost.png',
            width: 100,
            height: 100,
          },
          {
            src: '/static/assets/images/icons/ico-bonus-all.png',
            width: 100,
            height: 100,
          },
        ],
      },
    },
  },
  emitters: [
    {
      life: {
        count: 5,
      },
      position: {
        x: 75,
        y: -20,
      },
      rate: {
        delay: 0.2,
        quantity: 5,
      },
      particles: {
        move: {
          direction: 'bottom',
          speed: { min: 25, max: 50 },
        },
        size: {
          value: { min: 10, max: 15 },
        },
        number: {
          value: 0,
        },
      },
    },
    {
      life: {
        count: 5,
      },
      position: {
        x: 25,
        y: -20,
      },
      rate: {
        delay: 0.2,
        quantity: 5,
      },
      particles: {
        move: {
          direction: 'bottom',
          speed: { min: 25, max: 50 },
        },
        size: {
          value: { min: 10, max: 15 },
        },
        number: {
          value: 0,
        },
      },
    },
  ],
  preset: 'confetti',
};
