import { PARTICLES_CONFIG } from './ParticlesAnimation';
import TransactionHistoryItemMobile from './TransactionHistoryItemMobile';

export default {
  DISABLE_SPORTS_DROPDOWN_MOBILE: true,
  RACE_HOMEPAGE_SAME_AS_MOBILE: true,
  USE_SIGN_UP_MODAL: true,
  EXPANDED_SELECTION: true,
  animations: {
    texts: false,
    particles: true,
    signupBonusParticles: true,
    bonus: true,
    PARTICLES_CONFIG,
  },
  TransactionHistoryItemMobile,
  // PaymentMethodList,
};
