import React, { createContext, useContext, useCallback, useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Theme from 'themeSource/custom';
import Label from '../../shared/components/I18n/Label';

const JumpingCoinAnimationContext = createContext();

const JumpingCoinAnimationProvider = ({ children }) => {
  const isJumpingCoinEnabled = Theme?.animations?.bonus;
  const [isVisible, setIsVisible] = useState(false);
  const [prefix, setPrefix] = useState('');

  const calculatePosition = () => {
    const targetElement = document.querySelector('.account__current .user_profile');
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();
      return {
        opacity: 0,
        scale: 0,
        x: rect.left - window.innerWidth / 2 + 25,
        y: rect.top - window.innerHeight / 2 + 25,
      };
    }
  };

  const runJumpingCoinAnimation = useCallback(
    (duration, config) => {
      if (!isJumpingCoinEnabled) return;

      setIsVisible(true);
      setPrefix(config?.prefix ? config.prefix.toLowerCase() : '');

      setTimeout(() => {
        setIsVisible(false);
      }, duration);
    },
    [isJumpingCoinEnabled],
  );

  const contextValue = useMemo(() => ({ runJumpingCoinAnimation }), [runJumpingCoinAnimation]);

  return (
    <JumpingCoinAnimationContext.Provider value={contextValue}>
      {children}

      <AnimatePresence>
        {isJumpingCoinEnabled && isVisible && (
          <motion.div
            key='jumping-coin'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, delay: 1 }}
            className='jumping-coin-wrapper'
          >
            <div className='coin'>
              <motion.div
                className='front jump'
                initial={{ scale: 1, rotate: '44deg' }}
                animate={{ scale: 1 }}
                exit={calculatePosition()}
                transition={{ duration: 1 }}
              >
                <div className='star' />
                <span className='currency'>$</span>
                <div className='shapes'>
                  <div className='shape_l' />
                  <div className='shape_r' />
                  <span className='top'>
                    <Label message={`${prefix}_coin_anim_top`} />
                  </span>
                  <span className='bottom'>
                    <Label message={`${prefix}_coin_anim_bottom`} />
                  </span>
                </div>
              </motion.div>

              <div className='shadow' />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </JumpingCoinAnimationContext.Provider>
  );
};

const useJumpingCoinAnimation = () => {
  const context = useContext(JumpingCoinAnimationContext);

  if (!context) {
    throw new Error('useJumpingCoinAnimation must be used within a JumpingCoinAnimationProvider');
  }

  return context;
};

export { JumpingCoinAnimationProvider, useJumpingCoinAnimation };
