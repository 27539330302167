import React from 'react';
import { hasMarketTypes, isHidden } from '@apollo/core';
import { BonusSelector } from '../../../state/Bonus/Bonus';
import useTranslate from '../I18n/Interpreter';
import { RACE_STATUS } from '../../../core/constants';

const PromoOddsBonusIcon = ({ race = null, event = null }) => {
  const t = useTranslate();

  const isShow =
    (race && race.status === RACE_STATUS.OPEN) ||
    (event && hasMarketTypes(event) && !isHidden(event));

  return BonusSelector.hasActivePromoOdds(race?.raceId || event?.id) && isShow ? (
    <i className='icons icon--bet-bonus-all' title={t('promo odds')} />
  ) : null;
};

export default PromoOddsBonusIcon;
